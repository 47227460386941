import { FC } from 'react';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import IconProps from './IconProps';

const ThumbsUpIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      {...dataAttributeProps(props)}
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00008 13.9974H12.0001C12.5534 13.9974 13.0267 13.6641 13.2267 13.1841L15.2401 8.48406C15.3001 8.33073 15.3334 8.17073 15.3334 7.9974V6.66406C15.3334 5.93073 14.7334 5.33073 14.0001 5.33073H10.408C10.091 5.33073 9.85398 5.0394 9.91851 4.72897L10.4267 2.28406L10.4467 2.07073C10.4467 1.7974 10.3334 1.54406 10.1534 1.36406L9.80029 1.01427C9.60477 0.820589 9.28947 0.821336 9.09487 1.01594L5.05341 5.0574C4.81341 5.2974 4.66675 5.63073 4.66675 5.9974V12.6641C4.66675 13.3974 5.26675 13.9974 6.00008 13.9974ZM6.00008 6.2045C6.00008 6.07189 6.05276 5.94472 6.14653 5.85095L7.61479 4.38269C7.97353 4.02395 8.57679 4.36585 8.4533 4.85794L8.15609 6.04237C8.07687 6.35805 8.31558 6.66406 8.64105 6.66406H13.5001C13.7762 6.66406 14.0001 6.88792 14.0001 7.16406V7.89477C14.0001 7.96248 13.9863 8.02949 13.9597 8.09173L12.13 12.361C12.0512 12.5449 11.8704 12.6641 11.6704 12.6641H6.50008C6.22394 12.6641 6.00008 12.4402 6.00008 12.1641V6.2045ZM0.666748 6.4974C0.666748 6.22125 0.890606 5.9974 1.16675 5.9974H2.83341C3.10956 5.9974 3.33341 6.22125 3.33341 6.4974V13.4974C3.33341 13.7735 3.10956 13.9974 2.83341 13.9974H1.16675C0.890606 13.9974 0.666748 13.7735 0.666748 13.4974V6.4974Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ThumbsUpIcon;
